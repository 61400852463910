{
  "lang_offline_count": "உள்ளகத்தில் கிடைக்கும் உருப்படிகள்",
  "lang_offline_navigation_unavailable": "குறிச்சொற்கள் மற்றும் மூலங்களை மாற்றுவது தற்போது இணைப்பில்லாத பயன்முறையில் கிடைக்கவில்லை.",
  "lang_tags": "குறிச்சொற்கள்",
  "lang_alltags": "அனைத்து குறிச்சொற்களும்",
  "lang_timestamp": "{0,date}-{0,time}",
  "lang_days": "{0,plural,zero {இன்று},one {நேற்று},other {# நாட்களுக்கு முன்பு}}",
  "lang_star": "விண்மீன் இடு",
  "lang_unstar": "விண்மீன் நீக்கு",
  "lang_mark": "படித்தபடி குறி",
  "lang_unmark": "படிக்காதது எனக் குறி",
  "lang_load_img": "படங்களைக் காட்டு",
  "lang_open_window": "திற",
  "lang_next": "அடுத்தது",
  "lang_share_native_label": "பங்கு",
  "lang_share_diaspora_label": "புலம்பெயர்ந்தோரில் பகிர்",
  "lang_share_twitter_label": "கீச்சகத்தில் பகிர்",
  "lang_share_facebook_label": "முகநூலில் பகிர்",
  "lang_share_mastodon_label": "குட்டைப் பருத்தியில் பகிர்",
  "lang_share_pocket_label": "பையில் சேமி",
  "lang_share_wallabag_label": "வாலபாக் சேமி",
  "lang_share_wordpress_label": "சொல்அழுத்தில் பகிர்",
  "lang_share_mail_label": "மின்னஞ்சலைப் பயன்படுத்தி பகிர்",
  "lang_share_copy_label": "இடைநிலைப்பலகைக்கு இணைப்பை நகலெடு",
  "lang_search_label": "தேடம் சொல்",
  "lang_searchbutton": "தேடு",
  "lang_searchremove": "தேடலை மறை",
  "lang_refreshbutton": "மூலங்களைப் புதுப்பி",
  "lang_settingsbutton": "அமைப்புகள்",
  "lang_logoutbutton": "விடுபதிகை",
  "lang_loginbutton": "புகுபதிகை",
  "lang_sources": "மூலங்கள்",
  "lang_sources_loading": "மூலங்களை ஏற்றுகிறது…",
  "lang_no_sources": "எந்த மூலங்களும் கட்டமைக்கப்படவில்லை.",
  "lang_source_add": "மூலத்தைச் சேர்",
  "lang_source_opml": "அல்லது ஒபிஎம்எல் கோப்பை இறக்குமதி செய்",
  "lang_source_export": "ஏற்றுமதி மூலங்கள்",
  "lang_source_edit": "தொகு",
  "lang_source_filter": "வடிப்பி",
  "lang_source_menu": "மேலும் செயல்கள்",
  "lang_source_browse": "உலாவு",
  "lang_source_delete": "நீக்கு",
  "lang_source_warn": "உண்மையில் இந்த மூலத்தை நீக்கவா?",
  "lang_source_deleting": "மூலத்தை நீக்குதல்…",
  "lang_source_warn_cancel_dirty": "சேமிக்காத மாற்றங்களை நிராகரிக்கவா?",
  "lang_source_new": "புதிய சேமிக்கப்படாத மூல",
  "lang_source_title": "தலைப்பு",
  "lang_source_autotitle_hint": "தலைப்பைப் பெற காலியாக விடு",
  "lang_source_tags": "குறிச்சொற்கள்",
  "lang_source_pwd_placeholder": "மாற்றப்படவில்லை",
  "lang_source_comma": "காற்புள்ளியால் பிரிந்தது",
  "lang_source_select": "மூலத்தைத் தேர்ந்தெடு",
  "lang_source_params_loading": "அளவுருக்களை ஏற்றுகிறது…",
  "lang_source_type": "வகை",
  "lang_source_save": "சேமி",
  "lang_source_cancel": "நீக்கறல்",
  "lang_source_saving": "மூல மாற்றங்களைச் சேமிக்கிறது…",
  "lang_source_saved": "சேமிக்கப்பட்டது",
  "lang_source_last_post": "கடைசி இடுகை பார்த்தது",
  "lang_source_refresh": "இந்த மூலத்தைப் புதுப்பி",
  "lang_source_go_to_settings": "அமைப்புகளில் திற",
  "lang_sources_leaving_unsaved_prompt": "சேமிக்கப்படாத மூல மாற்றங்களுடன் அமைப்புகளை விடலாமா?",
  "lang_tag_change_color_button_title": "நிறத்தை மாற்று",
  "lang_entries_loading": "உள்ளீடுகளை ஏற்றுகிறது…",
  "lang_no_entries": "உள்ளீடுகள் எதுவும் கிடைக்கவில்லை",
  "lang_login_error_generic": "உள்நுழைய முடியவில்லை: {errorMessage}",
  "lang_error_check_log_file": "பிழை ஏற்பட்டது, தயவுசெய்து “{0}” பதிவுக் கோப்பை சரிபார்.",
  "lang_error_check_system_logs": "பிழை ஏற்பட்டது, உங்கள் கணினி பதிவுகளைச் சரிபார்.",
  "lang_error_unwritable_logs": "பதிவுகள் எழுத முடியவில்லை.",
  "lang_streamerror": "உருப்படிகள் ஏற்றப்படவில்லை, மீண்டும் ஏற்றுமா?",
  "lang_reload_list": "பட்டியல் மீண்டும் ஏற்று",
  "lang_sources_refreshed": "மூலங்கள் புதுப்பிக்கப்பட்டது",
  "lang_error_session_expired": "அமர்வு காலாவதியானது",
  "lang_error_add_source": "மூலத்தைச் சேர்க்க முடியவில்லை:",
  "lang_error_edit_source": "மூல மாற்றங்களைச் சேமிக்க முடியவில்லை:",
  "lang_error_delete_source": "மூலத்தை நீக்க முடியவில்லை:",
  "lang_error_load_tags": "குறிச்சொற்களை ஏற்ற முடியவில்லை:",
  "lang_error_unknown_tag": "தெரியாத குறிச்சொல்:",
  "lang_error_sync": "சேவையகத்திலிருந்து கடைசி மாற்றங்களை ஒத்திசைக்க முடியவில்லை:",
  "lang_error_offline_storage": "இணைப்பில்லாத சேமிப்பக பிழை: {0}. மீண்டும் ஏற்றுவது உதவக்கூடும். இப்போதைக்கு இணைப்பில்லாததை முடக்குகிறது.",
  "lang_markread": "படித்தபடி குறி",
  "lang_filter": "வடிப்பி",
  "lang_newest": "புதியது",
  "lang_unread": "படிக்காதது",
  "lang_starred": "விண்மீன் இடப்பட்டது",
  "lang_online_count": "சேவையகத்தில் கிடைக்கும் உருப்படிகள்",
  "lang_hours": "{0,plural,one {1 மணிநேரம்},other {# மணிநேரங்கள்}} முன்பு",
  "lang_minutes": "{0,plural,one {1 நிமையம்},other {# நிமையங்கள்}} முன்பு",
  "lang_seconds": "{0,plural,one {1 நொடி},other {# நொடிகள்}} முன்பு",
  "lang_more": "மேலும்",
  "lang_login": "புகுபதிகை",
  "lang_login_in_progress": "உள்நுழைய முயற்சிக்கிறது…",
  "lang_login_username": "பயனர்பெயர்",
  "lang_login_password": "கடவுச்சொல்",
  "lang_login_invalid_credentials": "தவறான பயனர்பெயர்/கடவுச்சொல்",
  "lang_login_offline": "இணைப்பில்லாத சேமிப்பு",
  "lang_no_title": "தலைப்பு இல்லை",
  "lang_experimental": "சோதனை",
  "lang_error": "பிழை ஏற்பட்டது",
  "lang_error_unauthorized": "தொடர {link_begin}உள்நுழைக{link_end}.",
  "lang_app_update": "செல்போச் புதுப்பிக்கப்பட்டுள்ளது, தயவுசெய்து மீண்டும் ஏற்று",
  "lang_app_reload": "மறுஏற்று",
  "lang_error_unknown_source": "தெரியாத மூல அடையாளம்:",
  "lang_error_mark_items": "புலப்படும் அனைத்து பொருட்களையும் குறிக்க முடியாது:",
  "lang_error_mark_item": "உருப்படியைக் குறிக்க/குறிநீக்க முடியாது:",
  "lang_error_star_item": "விண்மீன் இட/நீக்க முடியாத உருப்படி:",
  "lang_error_logout": "வெளியேற முடியவில்லை:",
  "lang_error_loading": "ஏற்றுதல் பிழை:",
  "lang_error_loading_stats": "வழிசெலுத்திய புள்ளிவிவரங்களை ஏற்ற முடியாது:",
  "lang_error_saving_color": "புதிய வண்ணத்தைச் சேமிக்க முடியாது:",
  "lang_error_refreshing_source": "மூலங்களைப் புதுப்பிக்க முடியாது:",
  "lang_error_invalid_subsection": "தவறான துணைபகுதி:",
  "lang_error_configuration": "உள்ளமைவை ஏற்ற முடியவில்லை.",
  "lang_error_share_native_abort": "உருப்படியைப் பகிர முடியவில்லை -பங்கு இலக்குகள் இல்லை, அல்லது நீங்கள் அதைத் தள்ளுபடி செய்தீர்கள்.",
  "lang_close_entry": "மூடு",
  "lang_error_offline_storage_not_available": "இணைப்பில்லாத சேமிப்பு கிடைக்கவில்லை. உங்கள் வலை உலாவி {0} அட்டவணைப்படுத்தப்பட்ட தரவுத்தளம் பநிஇ {1} ஐ ஆதரிக்கிறது என்பதை உறுதிப்படுத்திக் கொள்ளுங்கள், மேலும் நீங்கள் கூகிள் நிறமி அடிப்படையிலான உலாவியைப் பயன்படுத்துகிறீர்கள் என்றால், நீங்கள் மறைநிலை பயன்முறையில் செல்போசை இயக்கவில்லை என்பதை உறுதிப்படுத்தவும்.",
  "lang_error_share_native": "உருப்படியைப் பகிர முடியவில்லை.",
  "lang_info_url_copied": "முகவரி இடைநிலைப்பலகைக்கு நகலெடுக்கப்பட்டது.",
  "lang_article_actions": "செயல்கள்",
  "lang_article_reading_time": "{0} நிமையம் படித்தது"
}
